import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import querystring from "querystring";

import { Link } from "basis";

import ROUTES from "../../core/routes";
import {
  Authorising,
  InfoCard,
  Scene,
  fetchAccessToken,
} from "../../layout/shopify";

const Auth = ({ location }) => {
  const [state, setState] = useState({
    isLoading: true,
  });

  const { isLoading, appID } = state;

  const { search } = location;
  const query = querystring.parse(search.substring(1));

  const getShopifyAdminURL = (app = "") =>
    `https://${query?.shop}/admin/apps/${app}`;

  useEffect(() => {
    const tryFetchAccessToken = async () => {
      if (!isLoading) {
        return;
      }

      const { appID, error } = await fetchAccessToken(query);

      if (error) {
        navigate(`${ROUTES.ERROR}?message=${error}`);
        return;
      }

      setState({ isLoading: false, appID });

      setTimeout(() => {
        window.location.href = getShopifyAdminURL(appID);
      }, 3000);
    };

    tryFetchAccessToken();
  });

  return (
    <>
      {isLoading && <Authorising />}
      {!isLoading && (
        <Scene>
          <InfoCard
            title="Integration Complete"
            description="Thank you for choosing Latitude, you can now accept Latitude as a payment option through your website."
            addtDescription="If you do not get redirected to Shopify, Open app using link below."
          >
            {query?.shop && (
              <Link
                appearance="primary-button"
                variant="blue-button"
                href={getShopifyAdminURL(appID)}
                newTab={false}
              >
                Open App
              </Link>
            )}
          </InfoCard>
        </Scene>
      )}
    </>
  );
};

export default Auth;
